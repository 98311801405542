import React from "react"
import styled from "styled-components"
import { PhoneIcon } from "../Icons"
import PrimaryButton from "../Button/PrimaryButton"
import BreakpointUp from "../Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"

const BackgroundImage = styled.div`
  background-color: #0b619b;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  overflow: hidden;
`
const CallNow = styled.section`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  position: relative;
  padding: 60px 0;
  &:before {
    ${BreakpointUp.xl`		
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            border: 120px solid rgba(221,228,240,0.2);
            left: -250px;
            width: 500px;
            height: 500px;
            overflow: hidden;
            z-index: 1;
        `}
  }
`
const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-right: -15px;
  margin-left: -15px;
  z-index: 1;
  text-align: center;
  ${BreakpointUp.md`
        text-align: left;
    `}
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.md`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionGridRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.md`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
`
const ButtonToolbar = styled.div`
  .btn {
    &:hover {
      border-color: #fff;
    }
  }
`
const CallTitle = styled.h2`
  color: #fff;
  position: relative;
  z-index: 1;
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    font-size: 28px;
    line-height: 38px;
  }
  @media (min-width: 992px) {
    font-size: 32px;
    line-height: 42px;
  }
  &:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: 65%;
    color: rgba(255, 255, 255, 0.15);
    font-weight: 900;
    left: 0;
    width: 100%;
    display: none;
    @media (min-width: 768px) {
      display: block;
      font-size: 44px;
      line-height: 100px;
    }
    @media (min-width: 992px) {
      font-size: 85px;
      line-height: 95px;
    }
  }
`

const Cto = () => {
  return (
    <CallNow>
      <BackgroundImage>
        <StaticImage src="../../images/cta-bg.png" alt="cta-bg" />
      </BackgroundImage>
      <div className="container">
        <SectionGrid>
          <SectionGridLeft>
            <StaticImage src="../../images/carport.png" alt="carport" />
          </SectionGridLeft>
          <SectionGridRight>
            <CallTitle data-shadowtext="Call Us Now">
              If You Need Any Help, Call Us Now To Discuss More With Our
              Building Expert!
            </CallTitle>
            <ButtonToolbar>
              <a href="tel:8666817846" aria-label="(866) 681-7846">
                <PrimaryButton icon={<PhoneIcon />} text="(866) 681-7846" />
              </a>
            </ButtonToolbar>
          </SectionGridRight>
        </SectionGrid>
      </div>
    </CallNow>
  )
}

export default Cto
