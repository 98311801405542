import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Section,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
  MarkdownContent,
} from "../components/Section"
import CallNow from "../components/CallNow"
import PrimaryFormWhatToExpect from "../components/PrimaryFormWhatToExpect"
import ListArrow from "../images/BulletIcon.svg"

const SectionOverlayBg = styled.div`
  height: 100%;
  width: 30%;
  position: absolute;
  top: 0px;
  right: 0;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    top: 0;
    width: 90%;
    height: 100%;
    background: -moz-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-linear-gradient(
      left,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
  .gatsby-image-wrapper {
    height: 100%;
  }
`
const SectionOverlay = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`
const SectionOverlayContent = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  & p {
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 38px;
    }
  }
  & strong {
    color: #999;
    font-weight: 500;
  }
  & ul {
    list-style: none;
    margin: 0 0 30px;
    padding: 0;
    & li {
      padding-left: 20px;
      vertical-align: text-top;
      margin: 0;
      line-height: 26px;
      + li {
        margin-top: 15px;
      }
      &:before {
        content: url(${ListArrow});
        display: inline-block;
        margin-left: -20px;
        width: 20px;
        vertical-align: top;
      }
    }
  }
`

const WhatToExpect = ({ data, location }) => {
  const pageData = data.contentfulWhatToExpectPage
  return (
    <Layout location={location}>
      <Seo title={pageData.metaTitle} description={pageData.metaDescription} />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <Link to="/about-us">About Us / </Link>
          <span>{pageData.pageName}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0 overflow-intial"
      >
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <MarkdownContent>
                <h1>{pageData.heroTitle}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: pageData.heroContent.childMarkdownRemark.html,
                  }}
                />
              </MarkdownContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
                <PrimaryFormWhatToExpect states={data.allContentfulState.edges} location={location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="140px" pb="140px" xpt="60px" xpb="60px" bgColor="#fff">
        <SectionOverlayBg>
          <GatsbyImage
            image={pageData.readySectionImage.gatsbyImageData}
            alt={pageData.readySectionImage.title}
          />
        </SectionOverlayBg>
        <div className="container">
          <SectionOverlay>
            <SectionOverlayContent>
              <h2>{pageData.readySectionTitle}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    pageData.readySectionDescription.childMarkdownRemark.html,
                }}
              />
            </SectionOverlayContent>
          </SectionOverlay>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MarkdownContent maxWidth="950px">
            <div
              dangerouslySetInnerHTML={{
                __html: pageData.content.childMarkdownRemark.html,
              }}
            />
          </MarkdownContent>
        </div>
      </Section>
      <CallNow />
    </Layout>
  )
}

export default WhatToExpect

export const pageQuery = graphql`
  query WhatToExpectQuery {
    contentfulWhatToExpectPage {
      pageName
      metaTitle
      metaDescription
      heroTitle
      heroContent {
        childMarkdownRemark {
          html
        }
      }
      readySectionTitle
      readySectionDescription {
        childMarkdownRemark {
          html
        }
      }
      readySectionImage {
        title
        gatsbyImageData
      }
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
